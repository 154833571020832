import { getApp } from "firebase/app";
import {
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updatePassword
} from "firebase/auth";
import { useUserStore } from "~/stores/UserStore";
import resetAllStores from "~/stores/util/reset";

export const signInUser = (email, password) => {
  const app = getApp("clientApp");
  const auth = getAuth(app);
  return signInWithEmailAndPassword(
    auth,
    email,
    password
  );
};

const authStateChangedHandler = (auth) => onAuthStateChanged(auth, (user) => {
  const firebaseUser = useFirebaseUser();
  const userCookie = useCookie("userCookie");

  const currentRoute = useRoute();
  const isLoginRoute = currentRoute.path.includes("/login");

  if (user) {
    firebaseUser.value = user;
    // @ts-ignore
    userCookie.value = user; // ignore error because nuxt will serialize to json
    useUserStore().getUserFromDb();

    const isKioskRoute = currentRoute.path.startsWith("/kiosk");

    if (isLoginRoute) {
      if (isKioskRoute) {
        const careDayId = currentRoute.query.careDayId;
        return navigateTo(`/kiosk/${careDayId}`);
      }

      return navigateTo("/");
    }
  } else {
    // if signed out
    firebaseUser.value = null;
    userCookie.value = null;

    resetAllStores();

    if (!isLoginRoute) {
      return navigateTo("/login", { external: true });
    }
  }
});

export const signInUserToken = async (token) => {
  const app = getApp("clientApp");
  const auth = getAuth(app);

  const credentials = await signInWithCustomToken(auth, token).catch((error) => {
    console.error(error);
  });
  const user = credentials.user;

  const firebaseUser = useFirebaseUser();
  firebaseUser.value = user;

  const userCookie = useCookie("userCookie");
  userCookie.value = user;

  authStateChangedHandler(auth);
};

export const initUser = () => {
  const app = getApp("clientApp");
  const auth = getAuth(app);

  authStateChangedHandler(auth);

  const firebaseUser = useFirebaseUser();
  const userCookie = useCookie("userCookie");

  firebaseUser.value = auth.currentUser || userCookie.value;

  if (!firebaseUser.value) {
    return navigateTo("/login");
  }
};

export const signOutUser = async () => {
  const app = getApp("clientApp");
  const auth = getAuth(app);
  return await auth.signOut().then(() => {
    const firebaseUser = useFirebaseUser();
    const cookieUser = useCookie("userCookie");

    firebaseUser.value = null;
    cookieUser.value = null;
  });
};

export const changePassword = (oldPassword, newPassword) => {
  const app = getApp("clientApp");
  const auth = getAuth(app);
  const user = auth.currentUser;

  const credentials = EmailAuthProvider.credential(user.email, oldPassword);

  return reauthenticateWithCredential(user, credentials)
    .then(() => updatePassword(user, newPassword));
};
