import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://89e788113ceb97e0a3adce27bf2ad555@o4507820634800128.ingest.de.sentry.io/4507820637487184",
  maxBreadcrumbs: 50,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ],

  debug: false
});
